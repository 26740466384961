import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAnalyticsTablePageSize',
})
export class GetAnalyticsTablePageSizePipe implements PipeTransform {
  transform(tableSize?: number): number {
    return tableSize ? (tableSize < 3 ? tableSize : 3) : 3;
  }
}
