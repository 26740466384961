<h1 mat-dialog-title>
  {{ 'common.detail' | translate }}
</h1>
<!-- Test NX -->
<div mat-dialog-content>
  <table list class="full-width" *ngIf="hasData; else noDataScreen">
    <tr *ngFor="let title of titles">
      <td *ngIf="data[title] !== null">
        {{ 'dialog.title.' + title | translate }}
      </td>
      <td *ngIf="data[title] !== null" [ngSwitch]="data[title] | getType">
        <span *ngSwitchCase="'Date'">{{
          (data[title] | date: 'shortDate') +
            ', ' +
            (data[title] | date: 'mediumTime')
        }}</span>
        <ds-text-indicator
          *ngSwitchCase="'boolean'"
          [type]="data[title] ? 'success' : 'error'"
          >{{
            data[title] || ('general.not_available' | translate)
          }}</ds-text-indicator
        >
        <div *ngSwitchDefault>{{ data[title] }}</div>
      </td>
    </tr>
  </table>
  <ng-template #noDataScreen>
    <ds-text-indicator>{{ 'dialog.no_data' | translate }}</ds-text-indicator>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button matDialogClose>
    {{ 'common.close' | translate }}
  </button>
</div>
