<div
  class="alternative-template"
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  *ngIf="dataStream$ | async as dataStream"
>
  <ng-container *ngFor="let data of dataStream">
    <ds-alternative-table-renderer-item
      *ngIf="!data.tableHeaderGroup; else rowGroupHeader"
      [data]="data"
      [sort]="sort"
      [headerColumns]="headerColumns"
      [bodyColumns]="bodyColumns"
      [footerColumns]="footerColumns"
      [isExpansionPanelDisabled]="isExpansionPanelDisabled"
      (click)="tableElementClicked.emit(data)"
    >
    </ds-alternative-table-renderer-item>
    <ng-template #rowGroupHeader>
      <h2>{{ data.tableHeaderGroup }}</h2>
    </ng-template>
  </ng-container>
</div>
