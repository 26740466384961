import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { TableSettingsComponent } from './table-settings.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DisplayColumnsPipe } from './pipe/displayed-columns.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    TranslateModule,
    FormsModule,
    DragDropModule,
    DsSpacingModule,
    FlexLayoutModule,
  ],
  declarations: [TableSettingsComponent, DisplayColumnsPipe],
  exports: [TableSettingsComponent, DisplayColumnsPipe],
})
export class DsTableSettingsModule {}
