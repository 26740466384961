import { createSelector } from '@ngrx/store';
import { TelematicDoctorState } from '..';

export namespace InputSelectors {
  const getInputState = (state: TelematicDoctorState) => state.input;
  export const getIdentifier = createSelector(
    getInputState,
    (state) => state.identifier,
  );

  export const getHasIdentifier = createSelector(getInputState, (state) =>
    Boolean(
      state.identifier.eid?.length || state.identifier.equipmentNumber?.length,
    ),
  );
}
