import { Action, createReducer, on } from '@ngrx/store';
import { InputActions } from './input.actions';
import { LoadDiagnosticsPayload } from '../../shared';
import { DiagnosticsActions } from '../diagnostics/diagnostics.actions';

export interface InputState {
  identifier: LoadDiagnosticsPayload;
}

export const initialState: InputState = {
  identifier: {} as LoadDiagnosticsPayload,
};

const _inputReducer = createReducer(
  initialState,
  on(InputActions.SetIdentifier, (state, { payload }) => ({
    ...state,
    identifier: payload,
  })),
  on(DiagnosticsActions.LoadDiagnosticsSuccess, (state, { payload }) => ({
    ...state,
    identifier: {
      eid: state.identifier.eid || payload.eid || '',
      equipmentNumber:
        state.identifier.equipmentNumber || payload.equipment_number || '',
    },
  })),
);
export function inputReducer(state, action: Action): InputState {
  return _inputReducer(state, action);
}
