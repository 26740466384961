<mat-card data-cy="equipment-search">
  <mat-card-content>
    <form
      fxLayout="row"
      fxLayout.lt-md="column"
      [fxLayoutGap]="1 | dsSpacing"
      [fxLayoutGap.lt-md]="0.5 | dsSpacing"
      fxLayoutAlign="center center"
      [formGroup]="inputForm"
      [gaCategoryGroup]="'Input'"
    >
      <mat-form-field fxFlex="40">
        <mat-label>{{ 'common.eid' | translate }}</mat-label>
        <input
          matInput
          (keydown.Backspace)="eid.value = trimEID(eid.value)"
          formControlName="eid"
          pattern="[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}:[A-Za-z0-9]{2}"
          maxLength="17"
          #eid
          data-cy="eid-input"
          [gaClickListener]="'EID Input'"
        />
        <mat-hint> {{ 'common.eid_hint' | translate }}</mat-hint>
        <mat-error *ngIf="inputForm.controls.eid.invalid">
          {{ 'common.eid_warning' | translate }}</mat-error
        >
      </mat-form-field>
      <span>{{ 'or' | translate }}</span>
      <mat-form-field fxFlex="40">
        <mat-label>{{ 'common.equipment_number' | translate }}</mat-label>
        <input
          matInput
          formControlName="equipmentNumber"
          #equipmentNumber
          data-cy="equipment-number-input"
          [gaClickListener]="'Equipment Number Input'"
        />
      </mat-form-field>
      <button
        mat-raised-button
        color="accent"
        fxFlexAlign="center"
        [dsButtonPending]="isLoading"
        [disabled]="
          (!equipmentNumber.value && eid.value.toString().length < 17) ||
          isLoading
        "
        [routerLink]="[]"
        [queryParams]="{ equip_nr: equipmentNumber.value, eid: eid.value }"
        data-cy="request-btn"
        (click)="
          setIdentifier({
            equipmentNumber: equipmentNumber.value,
            eid: eid.value
          })
        "
        [gaClickListener]="'Submit Input'"
      >
        {{ 'common.go' | translate }}
      </button>
    </form>
  </mat-card-content>
</mat-card>
