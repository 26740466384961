import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DrawerStoreFeature } from '../drawer.module';

const drawerFeature =
  createFeatureSelector<DrawerStoreFeature>('drawerFeature');

const drawerState = createSelector(drawerFeature, (feature) => feature?.drawer);
export namespace DsDrawerSelectors {
  export const isSidebarExpanded = createSelector(
    drawerState,
    (state) => state?.isSidebarExpanded,
  );

  export const isSidebarHidden = createSelector(
    drawerState,
    (state) => state?.isSidebarHidden,
  );

  export const stickyOffset = createSelector(
    drawerState,
    (state) => state?.stickyOffset,
  );
}
