import { Action, createReducer, on } from '@ngrx/store';
import { DsDrawerActions } from './drawer.actions';

export interface DsDrawerState {
  isSidebarExpanded: boolean;
  isSidebarHidden: boolean;
  isSticky: boolean;
  stickyOffset: number;
}

export const initialDrawerState: DsDrawerState = {
  isSidebarExpanded: true,
  isSidebarHidden: false,
  isSticky: false,
  stickyOffset: 0,
};

const reducer = createReducer(
  initialDrawerState,
  on(DsDrawerActions.SetSidebarExpanded, (state, { isExpanded }) => ({
    ...state,
    isSidebarExpanded: isExpanded,
  })),
  on(DsDrawerActions.SetSidebarHidden, (state, { isHidden }) => ({
    ...state,
    isSidebarHidden: isHidden,
  })),
  on(DsDrawerActions.SetStickyOffset, (state, { offset }) => ({
    ...state,
    stickyOffset: offset,
  })),
);
export function drawerReducer(
  state: DsDrawerState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
