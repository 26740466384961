import { Sort } from '@angular/material/sort';
import { TableSettings } from '@design-system/components/table-settings';
import { createAction, props } from '@ngrx/store';
import { PageQuery } from '../../shared/models/page-query';
import {
  AbortDeviceOperationRequest,
  ApplySpecificConfigurationRequest,
  ApplySpecificFirmwareRequest,
  ApplySpecificSimStateRequest,
  ApprovedDeviceConfiguration,
  BidirectionalOperationsPagedResult,
  DeviceDiagnostics,
  DeviceOperationsResult,
  HealthCheckResponse,
} from '@paldesk/shared-lib/data-access/provisioning-service-generated';

export namespace DiagnosticsActions {
  export const LoadServerStates = createAction(
    '[TELEMATIC-DOCTOR] LOAD_SERVER_STATES',
  );
  export const LoadServerStatesLoadDiagnosticsSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_SERVER_STATES_SUCCESS',
    props<{ payload: HealthCheckResponse }>(),
  );
  export const LoadServerStatesError = createAction(
    '[TELEMATIC-DOCTOR] LOAD_SERVER_STATES_ERROR',
  );
  export const LoadDiagnostics = createAction(
    '[TELEMATIC-DOCTOR] LOAD_DIAGNOSTICS',
  );
  export const LoadDiagnosticsSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_DIAGNOSTICS_SUCCESS',
    props<{ payload: DeviceDiagnostics }>(),
  );
  export const LoadDiagnosticsError = createAction(
    '[TELEMATIC-DOCTOR] LOAD_DIAGNOSTICS_ERROR',
    props<{ payload: string }>(),
  );
  export const LoadDiagnosticsNoData = createAction(
    '[TELEMATIC-DOCTOR] LOAD_DIAGNOSTICS_NO_DATA',
    props<{ payload: string }>(),
  );

  export const LoadSuperUserData = createAction(
    '[TELEMATIC-DOCTOR] LOAD_SUPER_USER_DATA',
  );

  export const LoadApprovedConfigurationsSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_APPROVED_CONFIGURATIONS_SUCCESS',
    props<{ payload: ApprovedDeviceConfiguration[] }>(),
  );
  export const LoadApprovedConfigurationsError = createAction(
    '[TELEMATIC-DOCTOR] LOAD_APPROVED_CONFIGURATIONS_ERROR',
  );
  export const LoadApplicableSimStatesSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_APPLICABLE_SIM_STATES_SUCCESS',
    props<{ payload: string[] }>(),
  );
  export const LoadApplicableSimStatesError = createAction(
    '[TELEMATIC-DOCTOR] LOAD_APPLICABLE_SIM_STATES_ERROR',
  );
  export const LoadServerDeviceFirmwareVersionsSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_SERVER_DEVICE_FIRMWARE_VERSIONS_SUCCESS',
    props<{ payload: string[] }>(),
  );
  export const LoadServerDeviceFirmwareVersionsError = createAction(
    '[TELEMATIC-DOCTOR] LOAD_SERVER_DEVICE_FIRMWARE_VERSIONS_ERROR',
  );
  export const LoadDeviceOperationsSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_DEVICE_OPERATIONS_SUCCESS',
    props<{ payload: DeviceOperationsResult }>(),
  );
  export const LoadDeviceOperationsError = createAction(
    '[TELEMATIC-DOCTOR] LOAD_DEVICE_OPERATIONS_ERROR',
  );
  export const SaveQueryDeviceOperations = createAction(
    '[TELEMATIC-DOCTOR] SAVE_QUERY_DEVICE_OPERATIONS',
    props<{ payload: PageQuery }>(),
  );
  export const SaveSortDeviceOperations = createAction(
    '[TELEMATIC-DOCTOR] SAVE_SORT_DEVICE_OPERATIONS',
    props<{ payload: Sort }>(),
  );

  export const LoadDeviceInstructionSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_DEVICE_INSTRUCTION_SUCCESS',
    props<{ payload: BidirectionalOperationsPagedResult }>(),
  );
  export const LoadDeviceInstructionError = createAction(
    '[TELEMATIC-DOCTOR] LOAD_DEVICE_INSTRUCTION_ERROR',
  );
  export const SaveQueryDeviceInstruction = createAction(
    '[TELEMATIC-DOCTOR] SAVE_QUERY_DEVICE_INSTRUCTION',
    props<{ payload: PageQuery }>(),
  );

  export const LoadUserTableSettingsDeviceOperations = createAction(
    '[TELEMATIC-DOCTOR] LOAD_USER_TABLE_SETTINGS_DEVICE_OPERATIONS',
    props<{ allColumns: TableSettings[]; settingsKey: string }>(),
  );
  export const LoadUserTableSettingsDeviceOperationsSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_USER_TABLE_SETTINGS_DEVICE_OPERATIONS_SUCCESS',
    props<{ payload: TableSettings[] }>(),
  );

  export const PostApplySpecificFirmware = createAction(
    '[TELEMATIC-DOCTOR] POST_APPLY_SPECIFIC_FIRMWARE',
    props<{ payload: ApplySpecificFirmwareRequest }>(),
  );
  export const PostApplySpecificFirmwareSuccess = createAction(
    '[TELEMATIC-DOCTOR] POST_APPLY_SPECIFIC_FIRMWARE_SUCCESS',
  );
  export const PostApplySpecificFirmwareError = createAction(
    '[TELEMATIC-DOCTOR] POST_APPLY_SPECIFIC_FIRMWARE_ERROR',
  );

  export const PostApplySpecificSimState = createAction(
    '[TELEMATIC-DOCTOR] POST_APPLY_SPECIFIC_SIM_STATE',
    props<{ payload: ApplySpecificSimStateRequest }>(),
  );
  export const PostApplySpecificSimStateSuccess = createAction(
    '[TELEMATIC-DOCTOR] POST_APPLY_SPECIFIC_SIM_STATE_SUCCESS',
  );
  export const PostApplySpecificSimStateError = createAction(
    '[TELEMATIC-DOCTOR] POST_APPLY_SPECIFIC_SIM_STATE_ERROR',
  );
  export const PostApplySpecificConfiguration = createAction(
    '[TELEMATIC-DOCTOR] POST_APPLY_SPECIFIC_CONFIGURATION',
    props<{ payload: ApplySpecificConfigurationRequest }>(),
  );
  export const PostApplySpecificConfigurationSuccess = createAction(
    '[TELEMATIC-DOCTOR] POST_APPLY_SPECIFIC_CONFIGURATION_SUCCESS',
  );
  export const PostApplySpecificConfigurationError = createAction(
    '[TELEMATIC-DOCTOR] POST_APPLY_SPECIFIC_CONFIGURATION_ERROR',
  );
  export const PostAbortDeviceOperation = createAction(
    '[TELEMATIC-DOCTOR] POST_ABORT_DEVICE_OPERATION',
    props<{ id: number; payload: AbortDeviceOperationRequest }>(),
  );
  export const PostAbortDeviceOperationSuccess = createAction(
    '[TELEMATIC-DOCTOR] POST_ABORT_DEVICE_OPERATION_SUCCESS',
  );
  export const PostAbortDeviceOperationError = createAction(
    '[TELEMATIC-DOCTOR] POST_ABORT_DEVICE_OPERATION_ERROR',
  );

  export const PutAbortDeviceInstruction = createAction(
    '[TELEMATIC-DOCTOR] PUT_ABORT_DEVICE_INSTRUCTION',
    props<{ id: number; eid: string }>(),
  );
  export const PutAbortDeviceInstructionSuccess = createAction(
    '[TELEMATIC-DOCTOR] PUT_ABORT_DEVICE_INSTRUCTION_SUCCESS',
    props<{ id: number }>(),
  );
  export const PutAbortDeviceInstructionError = createAction(
    '[TELEMATIC-DOCTOR] PUT_ABORT_DEVICE_INSTRUCTION_ERROR',
    props<{ id: number }>(),
  );
}
