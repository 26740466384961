import { createAction, props } from '@ngrx/store';

export namespace DsDrawerActions {
  export const SetSidebarExpanded = createAction(
    '[DsDrawer] SET_SIDEBAR_EXPANDED',
    props<{
      isExpanded: boolean;
    }>(),
  );

  export const SetSidebarHidden = createAction(
    '[DsDrawer] SET_SIDEBAR_HIDDEN',
    props<{
      isHidden: boolean;
    }>(),
  );

  export const SetStickyOffset = createAction(
    '[DsDrawer] SET_STICKY_OFFSET',
    props<{
      offset: number;
    }>(),
  );
}
