import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { DsDeprecatedFilterComponent } from './deprecated/filter-deprecated.component';
import { DsFilterDrawerComponent } from './filter-drawer.component';
import {
  DsFilterComponent,
  DsFilterItemDirective,
  FilterWrapperComponent,
} from './filter.component';

@NgModule({
  imports: [
    CommonModule,
    DsSpacingModule,
    FlexLayoutModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
  exports: [
    DsDeprecatedFilterComponent,
    DsFilterComponent,
    DsFilterItemDirective,
  ],
  declarations: [
    DsDeprecatedFilterComponent,
    DsFilterComponent,
    DsFilterItemDirective,
    DsFilterDrawerComponent,
    FilterWrapperComponent,
  ],
})
export class DsFilterModule {}
