import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getType',
})
export class GetTypePipe implements PipeTransform {
  transform(val: any): string {
    return typeof val;
  }
}
