<table class="full-width">
  <tr>
    <th *ngFor="let i of counter(numberOfHeaders)">
      <ds-loading-placeholder></ds-loading-placeholder>
    </th>
  </tr>
  <tr *ngFor="let i of counter(numberOfRows)">
    <td [attr.colspan]="numberOfHeaders">
      <ds-loading-placeholder></ds-loading-placeholder>
    </td>
  </tr>
</table>
