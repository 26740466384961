import { Pipe, PipeTransform } from '@angular/core';
import { TableSettings } from '../models/index';
/**
 * Returns the index of an element of an array
 */
@Pipe({
  name: 'displayColumns',
})
export class DisplayColumnsPipe implements PipeTransform {
  transform(arr: Array<TableSettings>): Array<string> {
    return arr.filter((c) => c.selected).map((c) => c.name);
  }
}
